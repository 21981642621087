<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('failedJobs.tablePageTitle') | title" />

    <huboo-table
      id="failedJobTable"
      @filter="handleFilter"
      @row-clicked="onSelected"
      @search="handleSearch"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="meta.total"
      clickable
      hide-search
      v-bind="options"
    >
    </huboo-table>
  </huboo-page>
</template>

<script>
import i18n from '@/boot/i18n'
import { title } from '@/utilities/filters'
import { mapGetters } from 'vuex'
import { handleSearch } from '@/views/pages/billing/billingViewHelpers.js'
import { isLoading } from '@/utilities/helpers/views'
import ClientMixin from '@/mixins/client'

const tableHeaders = [
  {
    text: title(i18n.t('failedJobs.tableHeaders.id')),
    sortable: false,
    value: 'id',
  },
  {
    text: title(i18n.t('failedJobs.tableHeaders.connection')),
    sortable: false,
    value: 'attributes.connection',
  },
  {
    text: title(i18n.t('failedJobs.tableHeaders.queue')),
    sortable: false,
    value: 'attributes.queue',
  },
  {
    text: title(i18n.t('failedJobs.tableHeaders.payload')),
    sortable: false,
    value: 'attributes.payload',
  },
  {
    text: title(i18n.t('failedJobs.tableHeaders.exception')),
    sortable: false,
    value: 'attributes.exception',
  },
  {
    text: title(i18n.t('failedJobs.tableHeaders.failedAt')),
    sortable: true,
    value: 'attributes.failedAt',
  },
]

export default {
  name: 'FailedJobs',
  mixins: [ClientMixin],
  components: {},
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
  }),
  computed: {
    ...mapGetters('core', ['getIsAdmin']),
    isAdmin() {
      return this.getIsAdmin
    },
    getFailedJobs() {
      return this.$store.getters['failedJobs/getAll']
    },
    headers() {
      return tableHeaders
    },
    items() {
      return this.failedJobs
    },
    loading() {
      return isLoading(this.url)
    },
    getMeta() {
      return this.$store.getters['failedJobs/getMeta']
    },
    meta() {
      return { total: this.getMeta.total || this.items.length }
    },
    failedJobs() {
      const lists = this.getFailedJobs
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
          loading: this.removeLoading(l.id),
        }))
      else return []
    },
    url() {
      return this.urlEntity
    },
    urlEntity() {
      return '/failed-jobs/'
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    fetch() {
      const { options, search } = this
      const { page, itemsPerPage } = options
      const params = {}
      if (page) params.page = page
      if (itemsPerPage) params.itemsPerPage = itemsPerPage
      if (search) params.query = search
      return this.$store.dispatch('failedJobs/fetchCollection', { params })
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    onSelected(e) {
      // disable select if not admin
      const selectedArray = Object.entries(e)
      const stringToBool = selectedArray.map(item => {
        if (item[1] === 'Yes') {
          return [item[0], true]
        }
        if (item[1] === 'No') {
          return [item[0], false]
        } else {
          return item
        }
      })

      this.selected = stringToBool.reduce((acc, item) => {
        acc[item[0]] = item[1]
        return acc
      }, {})
      this.editModal = true
    },

    onUpdatedItem() {
      this.fetch()
    },

    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.urlEntity + id,
        method: 'DELETE',
      })
    },
    handleUpdateOptions(newOptions) {
      console.log('updated options', newOptions);

      this.options = {
        ...this.options,
        page: newOptions.page,
        itemsPerPage: newOptions.itemsPerPage,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
